.searchBar__container {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;

  &--title {
    font-family: "Azonix";
    background: transparent;
    border: none;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgray;
    color: white;
    text-align: center;
  }
}
