.optionWeather__container {
  display: flex;
  justify-content: space-around;
  position: relative;
  bottom: 12px;
  font-size: 0.7em;
  color: lightgray;

  &--wind-title,
  &--humidity-title,
  &--pollen-title {
    padding: 10px;
  }
}
