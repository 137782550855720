@font-face {
  font-family: "Azonix";
  src: local("Azonix"), url(../../Font/Azonix.otf) format("truetype");
}

@font-face {
  font-family: "Resotho";
  src: local("Resotho"), url(../../Font/Resotho.otf) format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  min-height: 100vh;
  text-align: center;
  color: whitesmoke;
  font-family: "Azonix";
  background: linear-gradient(#0c0d40, #404a72);

  // Another mode
  &--isActive {
    height: 100vh;
    background: linear-gradient(#e1751a, #e6c027);
    text-align: center;
    color: whitesmoke;
    font-family: "Azonix";
  }
}
