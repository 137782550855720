.header__container {
  height: 50px;

  &--date {
    font-family: "Resotho";
    font-size: 17px;
    color: lightgrey;
    padding: 10px;
  }
}
